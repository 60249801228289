<template>
  <div>
    <b-modal id="file-group-record" modal-class="common-modal common-file-group-record-modal" hide-footer no-close-on-backdrop>
    <button v-if="fileGroupInfo.next_filegroup_id" class="floating-arrow left" @click="changeRecord('right')">&lt;</button>
      <template slot="modal-header">
        <h5 class="modal-title">{{ fileGroupInfo.name || 'Ficha de Archivo' }}</h5>
        <h5 class="ml-auto" v-if="user.is_superuser || user.profile.permissions.includes('review_file_groups')">
        <b-link @click="sendReview(fileGroupInfo.id)">{{ $t('general.sendReview') }}</b-link>
        </h5>
        <button @click="hideModal()" class="close"></button>
      </template>
      <h6>{{ $t('general.date') }}: {{ customFormatter(fileGroupInfo.create_at) }}</h6>
      <div class="list-tags">
        {{ $t('general.tags') }}:
        <slot v-for="tag in fileGroupInfo.tags">
          <label-tag small v-if="tag" :variant="fileGroupInfo.description">{{ tag.description }}</label-tag
          >&nbsp;
        </slot>
      </div>
      <br />
      <div id="contenedor">
        <div>
          <!-- Files -->
          <slot v-if="fileGroupInfo.files">
            <template v-if="containsImages(fileGroupInfo.displayFiles) || containsVideos(fileGroupInfo.displayFiles)">
              <carousel-media :displayFiles="fileGroupInfo.displayFiles"></carousel-media>
            </template>
            <template v-else-if="containsAudios(fileGroupInfo.displayFiles)">
              <audio-player :fileGroupInfo="fileGroupInfo"></audio-player>
            </template>
            <template v-else>
              <!-- Puedes mostrar un mensaje o componente por defecto cuando no hay imágenes ni audios -->
              <p>No hay imágenes ni archivos de audio disponibles.</p>
            </template>
          </slot>
        </div>
        <div>
          <h4>{{ $t('general.description') }}</h4>
          <hr />
          <p>
            <slot>{{ fileGroupInfo.description }}</slot>
          </p>
        </div>
      </div>
      <br />
      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>
      <div v-else>
        <slot v-if="!loadingInfo && fileGroupInfo.files.length > 0">
          <b-table responsive :fields="tableColumns" :items="fileGroupInfo.files" class="common-table-container">
            <template v-slot:cell(actions)="data">
              <b-link @click="download(data.item.file, data.item.name, data.item.extension)">{{ $t('general.download') }}</b-link>
            </template>
            <template v-slot:cell(username)>
              {{ fileGroupInfo.username }}
            </template>
          </b-table>
        </slot>
      </div>
    <button v-if="fileGroupInfo.previous_filegroup_id" class="floating-arrow right" @click="changeRecord('left')">&gt;</button>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import './FileGroupRecord.scss';
</style>

<script>
import commonSrv from '@/api/common';
import filesSrv from '@/api/files';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import { showSuccess } from '@/components/ScreenAlert';

export default {
  components: {
    LabelTag: () => import('@/components/LabelTag/LabelTag.vue'),
    CarouselMedia: () => import('@/components/Carousel/Carousel.vue'),
    AudioPlayer: () => import('@/components/AudioPlayer/AudioPlayer.vue'),
  },

  props: {
    fileGroupRecordId: Number,
  },

  data() {
    return {
      loadingInfo: false,
      fileGroupInfo: Object,
      tableColumns: [
        { key: 'extension', label: i18n.t('general.format') },
        { key: 'name', label: i18n.t('general.fileName') },
        { key: 'username', label: i18n.t('general.publishedBy') },
        { key: 'actions', label: i18n.t('general.actions') },
      ],
      items: [],
    };
  },
  created() {
    this.getFiles();
  },

  methods: {
    hideModal() {
      this.$emit('closeModal');
    },
    sendReview(fileGroupId) {
      const data = {
        status: 'in_review',
      };
      filesSrv
        .UpdateStatusFile(fileGroupId, data)
        .then(() => {
          this.$router.push({ name: 'visual-vault' }).catch(() => {});
          showSuccess({
            confirmLabel: '',
            title: i18n.t('general.information'),
            content: i18n.t('general.successfulOperation'),
          });
          // ACTUALIZAR AQUÍ
          this.beforeDestroy();
        })
        .catch(({ message, error }) => {
          this.formResponse = message || error;
        });
    },
    getFiles() {
      this.loadingInfo = true;
      return filesSrv
        .getFileGroupRecord(this.fileGroupRecordId)
        .then((resp) => {
          const displayFiles = resp.files.filter(
            (file) => this.isImage(file.extension) || this.isVideo(file.extension) || this.isAudio(file.extension),
          );
          this.fileGroupInfo = { ...resp, displayFiles };
        })
        .finally(() => {
          this.loadingInfo = false;
        });
    },
    changeRecord(direction) {
      if (direction === 'left') {
        this.fileGroupRecordId = this.fileGroupInfo.previous_filegroup_id;
      } else if (direction === 'right') {
        this.fileGroupRecordId = this.fileGroupInfo.next_filegroup_id;
      }
      this.getFiles();
    },
    download(fileFile, fileName, fileExtension) {
      return commonSrv.downloadFile(fileFile, fileName, fileExtension, 'application/json');
    },
    customFormatter(date) {
      return commonSrv.customDateFormat(date, 'DD/MM/YYYY');
    },
    containsImages(files) {
      return files.some((file) => this.isImage(file.extension));
    },
    containsVideos(files) {
      return files.some((file) => this.isVideo(file.extension));
    },
    containsAudios(files) {
      return files.some((file) => this.isAudio(file.extension));
    },
    isImage(extension) {
      return ['png', 'jpg', 'jpeg', 'svg', 'gif'].includes(extension.toLowerCase());
    },
    isVideo(extension) {
      return ['mp4', 'avi', 'webm', 'mov', 'wmv', 'mkv', 'mpeg4', '3gp'].includes(extension.toLowerCase());
    },
    isAudio(extension) {
      return ['wav', 'mp3', 'ogg'].includes(extension.toLowerCase());
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
};
</script>
